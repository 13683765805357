import React from "react";
import { Typography } from "antd";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  murtecHero,
  murtecWhiteGlove,
  murtecBoothData,
  showRotarySection,
} from "../../data/events/murtec";
import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
import heroBg from "../../images/hero-images/nightclub-bar-event-hero.png";
import RotarySection from "../../components/RotarySection";
import EventForm from "../../components/EventsComps/EventForm";
import BoothCTA from "../../components/EventsComps/BoothCTA";
import Heading from "../../components-v2/Base/HeadingBuilder";

const whiteGloveImport = "event-white-glove.png";
const { Title } = Typography;

const customHero = () => {
  return (
    <>
      <Heading
        level={1}
        injectionType={4}
        className="hero-b__title text-center"
        style={{ fontSize: 42 }}
      >
        Join SpotOn at MURTEC
        <span className="blue_dot">.</span>
        <br />
        <p
          style={{
            color: "#1769FF",
            fontSize: "32px",
            marginBottom: "24px",
            lineHeight: 1.5,
          }}
        >
          Orlando, Florida | October 13 - 15, 2021
        </p>
        <p
          className="text-center"
          style={{
            color: "#748093",
            fontSize: 20,
            marginTop: "24px",
            fontWeight: 400,
            lineHeight: 1.5,
          }}
        >
          Join SpotOn at MURTEC in Orlando, Florida to see why our integrated
          technology is a better way to run your restaurant. Or click below to
          get a demonstration.
        </p>
      </Heading>
    </>
  );
};

const MURTEC = () => {
  return (
    <Layout isLanding phoneNumber="+18882246506" phoneText="(888) 224-6506">
      <SEO
        title="MURTEC | SpotOn | Restaurant POS and software"
        description="Join SpotOn at MURTEC October 13 - 15, in Orlando, Florida, to see why SpotOn is a better way to run your restaurant."
        image={`https://spoton-website-staging.netlify.app/${heroBg}`}
      />
      <BHero
        sectionData={murtecHero}
        heroBg="nightclub-bar-event-hero.png"
        clearBg
        customHeroContent={customHero()}
      />
      <div style={{ marginTop: "64px" }} />
      <WhiteGlove
        sectionData={murtecWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <BoothCTA sectionData={murtecBoothData} />
      <RotarySection sectionData={showRotarySection} />
      <EventForm
        id="demo"
        leadType="tradeshow"
        formId="3802e416-0f35-43ee-936a-1b1cc7037886"
        thankYouPath="/events/thank-you"
      />
      {/*      <Articles
        sectionData={murtecArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
    </Layout>
  );
};

export default MURTEC;
